<template>
  <div>
    <div class="container plan-container mb-4 mt-4">
      <div class="stories-title mb-4 mt-4">
        The best test prep tool, for the best price.
      </div>
      <Plan></Plan>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Landing from "@/views/landingPage/components/Index.vue";
import Plan from "@/views/plans/Students.vue";

export default {
  metaInfo() {
    return {
      title: "Plans - " + this.CompanyName
    };
  },

  components: { Plan },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.stories-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  /* font-style: italic; */
  font-family: "Oswald-Bold";
}
.plan-container {
  max-width: calc(1280px + 2 * var(--layout-padding));
  margin: 0 auto;
  padding: 0 var(--layout-padding);
  width: auto;
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
}
</style>